@font-face {
  font-family: 'regiomove';
  src:  url('fonts/regiomove.eot?xfyceo');
  src:  url('fonts/regiomove.eot?xfyceo#iefix') format('embedded-opentype'),
  url('fonts/regiomove.woff2?xfyceo') format('woff2'),
  url('fonts/regiomove.ttf?xfyceo') format('truetype'),
  url('fonts/regiomove.woff?xfyceo') format('woff'),
  url('fonts/regiomove.svg?xfyceo#regiomove') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="regio_icon-"], [class*=" regio_icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'regiomove' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.regio_icon-tgo:before {
  content: "\e902";
}
.regio_icon-myshuttle:before {
  content: "\e901";
}
.regio_icon-smap:before {
  content: "\e900";
}
.regio_icon-stadtmobil:before {
  content: "\21";
}
.regio_icon-stadtmobil_reduced:before {
  content: "\22";
}
.regio_icon-nextbike:before {
  content: "\23";
}
.regio_icon-kvv:before {
  content: "\24";
}
.regio_icon-regiomove:before {
  content: "\25";
}
.regio_icon-regiomoveline:before {
  content: "\26";
}
.regio_icon-zeo:before {
  content: "\e903";
}
